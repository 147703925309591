export const eventPrefix = 'jdl_';
export const theme = window.theme;

/**
 * Indicates the presence of a `jdl-debug` query parameter or a hostname of 127.0.0.1.
 *
 * @type {boolean}
 */
export const shouldLog =
	window.location.search.includes('jdl-debug') ||
	window.location.hostname === '127.0.0.1';

/**
 * Pretty prints a message to the console.
 *
 * @param {string} message The console message.
 * @param {string} [heading=null] A heading block for the message, if desired.
 */
export function log(message, heading = null) {
	const styles = 'background:#555;color:#fff;padding:4px 8px;';
	const headingString = heading ? ` | ${heading}` : '';

	console.log(`%cJonah${headingString}`, styles, message);
}

/**
 * Adds an object to the global dataLayer array.
 *
 * @param {*} data The object to add to the data layer.
 * @param {boolean} [log=false] If true, logs the event and data object to the console.
 */
export function push(data, log = false) {
	if (!Array.isArray(window.dataLayer)) {
		window.dataLayer = [];
	}

	window.dataLayer.push(data);

	if (log) {
		const styles = 'background:#555;color:#fff;padding:4px 8px;';
		console.log(`%cJonah ${data.event}`, styles, data);
	}
}

/**
 * Calculates the difference between two dates.
 *
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number} The number of days between the two dates, rounded down to the nearest whole day.
 */
export function daysBetween(date1, date2) {
	const millisInDay = 1000 * 60 * 60 * 24;
	const diffInDays = Math.floor((date1 - date2) / millisInDay) + 1;
	return diffInDays;
}

/**
 *
 * @param {Date} dateToFormat
 * @returns {string} The `Date` object formatted as mm/dd/yyyy.
 */
export function getFormattedDate(dateToFormat) {
	const month = dateToFormat.getMonth() + 1;
	const day = dateToFormat.getDate();
	const year = dateToFormat.getFullYear();
	return `${month}/${day}/${year}`;
}

/**
 * Polls `window` for the presence of a variable.
 *
 * @param {string} variableName The variable or object to poll for.
 * @param {function} callback A callback to run when the variable is present.
 * @param {number} timeout Milliseconds to wait for the variable.
 * @param {number} interval Milliseconds to wait between polls.
 */
export function waitForVariable(
	variableName,
	callback,
	timeout = 5000,
	interval = 100,
) {
	const startTime = Date.now();
	const checkInterval = setInterval(() => {
		if (window[variableName]) {
			clearInterval(checkInterval);
			callback(window[variableName]);
		}

		if (Date.now() - startTime > timeout) {
			clearInterval(checkInterval);
		}
	}, interval);
}

/**
 *
 * @param {string} selector A CSS selector representing the element(s) to wait for.
 * @param {function} callback A callback to run when the variable is present.
 * @param {Element} ancestor The HTML Element from which to query.
 * @param {number} timeout Milliseconds to wait for the variable.
 * @param {number} interval Milliseconds to wait between polls.
 */
export function waitForElements(
	selector,
	callback,
	ancestor = document,
	timeout = 5000,
	interval = 100,
) {
	const startTime = Date.now();
	const checkInterval = setInterval(() => {
		const elements = ancestor.querySelectorAll(`:scope ${selector}`);

		if (elements.length > 0) {
			clearInterval(checkInterval);
			callback(elements);
		}

		if (Date.now() - startTime > timeout) {
			clearInterval(checkInterval);
		}
	}, interval);
}

/**
 *
 * @returns {string} Slug value representing the current community.
 */
export function getCommunity() {
	if (jcms && jcms.property) {
		return jcms.property.slug;
	} else {
		waitForVariable('jcms', () => {
			if (jcms.property) {
				return jcms.property.slug;
			} else {
				return;
			}
		});
	}
}

/**
 *
 * @returns {string} The current product, either `premium` or `corporate`.
 */
export function getProduct() {
	if (jcms) {
		return jcms.product_name;
	} else {
		waitForVariable('jcms', () => {
			return jcms.product_name;
		});
	}
}
